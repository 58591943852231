import { Menu } from "@headlessui/react"
import { Dropdown } from "components/dropdown/dropdown"
import { Icon, ICONS } from "components/icon/icon"
import { ReactNode } from "react"
import { Link } from "react-router-dom"

interface NavigationLink {
  name: string
  path: string
}

export interface NavigationCategory {
  name: string
  items: NavigationLink[]
}

export const NavigationMenu = ({
  trigger,
  navigationCategoryArray,
  title,
  footer,
}: {
  trigger: ReactNode
  navigationCategoryArray: NavigationCategory[]
  title?: string
  footer?: ReactNode
}) => {
  return (
    <Dropdown
      style={{ borderRadius: "8px", border: "1px solid #ced4da" }}
      trigger={trigger}
    >
      <div className="grid pt-2.5 pb-3">
        {title && (
          <div className="bg-white py-2.5 px-5" style={{ borderRadius: "8px" }}>
            <span className="text-sm font-medium text-gray-400 uppercase">{title}</span>
          </div>
        )}
        <div>
          {navigationCategoryArray.map((category, index) => (
            <div key={`category-${index}`}>
              <div className="text-caption-1 text-gray-500 font-medium py-[3px] px-5">
                {category.name}
              </div>
              <div className="flex flex-col">
                {category.items.map((item) => (
                  <Menu.Item>
                    <Link
                      to={item.path}
                      className="text-body font-medium text-gray-900 py-2.5 px-10 hover:bg-gray-100"
                    >
                      {item.name}
                    </Link>
                  </Menu.Item>
                ))}
              </div>
            </div>
          ))}
          <div className="pt-3">{footer && footer}</div>
        </div>
      </div>
    </Dropdown>
  )
}
